/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.example-spacer {
  flex: 1 1 auto;
}
/*.mat-grid-tile .mat-figure {
   justify-content: flex-start !important ;
   align-items: flex-start !important;
}
::ng-deep md-grid-tile.mat-grid-tile .mat-figure {
 /* align-items: flex-start;  /*vertical alignment*
  justify-content: initial;  /*horizontal alignment*
}*/
html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
 /*  background-image: url("./assets/images/homehome.jpg"); */
  background-color: #f5f5f593;
}
